<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <!-- Email List -->
    <div class="email-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchQuery"
              placeholder="Search ticket (hit enter to start)"
              @keyup.enter="updateFilterChannel"
            />
            <b-input-group-append v-if="searchQuery">
              <b-button
                variant="outline-primary"
                @click.prevent="clearSearchData"
              >
                <feather-icon
                  icon="XIcon"
                  size="14"
                /> Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <!-- App Action Bar -->
      <div class="app-action align-items-center">
        <div class="mr-auto action-left">
          <b-form-checkbox
            :checked="selectAllEmailCheckbox"
            :indeterminate="isSelectAllEmailCheckboxIndeterminate"
            @change="selectAllCheckboxUpdate"
          >
            Select All
          </b-form-checkbox>
        </div>
        <div
          v-show="selectedEmails.length"
          class="align-items-center mr-1"
          :class="{'d-flex': selectedEmails.length}"
        >
          <!-- Update Ticket Priority Dropdown -->
          <b-dropdown
            v-b-tooltip.hover.top="'Change Priority'"
            variant="link"
            no-caret
            toggle-class="p-0"
            class="ml-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="TagIcon"
                size="17"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-for="label in emailPriority"
              :key="label.route.params.priority"
              @click="updateSelectedEmailsLabel(label.route.params.priority)"
            >
              <span
                class="bullet bullet-sm mr-50"
                :class="`bullet-${label.color}`"
              />
              <span>{{ label.title }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <v-select
          v-model="filterChannel"
          :options="reference.channel"
          style="min-width:120px"
          class="small p-0"
          label="name"
          placeholder="Channel"
          @input="updateFilterChannel"
        />

        <b-button
          size="sm"
          variant="outline-primary"
          class="m-0 ml-50"
          @click="toggleOrder"
        >
          <feather-icon
            :icon="orderToggleIcon"
            size="14"
            class="mr-25"
          /> Order
        </b-button>
      </div>

      <!-- Emails List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="email-user-list scroll-area"
        @ps-y-reach-end="scrollHandle"
      >
        <ul class="email-media-list">
          <b-media
            v-for="ticket in emails"
            :key="ticket.ticket_id"
            tag="li"
            no-body
            :class="(ticket.status !== 'Closed' && ticket.lastreply !== null && ticket.lastreply.author_type === 'author') ? 'border-primary' : ''"
            @click="viewDetail(ticket)"
          >
            <b-media-aside class="media-left mr-50">
              <b-avatar
                class="avatar"
                size="40"
                variant="primary"
                :text="avatarText(ticket.author)"
              />
              <div class="user-action">
                <b-form-checkbox
                  :checked="selectedEmails.includes(ticket.ticket_id)"
                  @change="toggleSelectedMail(ticket.ticket_id)"
                  @click.native.stop
                />
              </div>
            </b-media-aside>

            <ticket-media-card
              :ticket="ticket"
              :reference="holidays"
            />
          </b-media>
        </ul>
        <div
          class="no-results"
          :class="{'show': !emails.length}"
        >
          <h5>No Items Found</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <ticket-left-sidebar
        :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
        :emails-meta="emailsMeta"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>

    <!-- Compose Email Modal -->
    <ticket-compose
      v-model="shallShowEmailComposeModal"
      :reference="reference"
      @new-ticket="newTicket"
    />
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BDropdown, BDropdownItem,
  BFormCheckbox, BMedia, BMediaAside, BAvatar, BButton, VBTooltip,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import vSelect from 'vue-select'
import moment from 'moment-business-time'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// eslint-disable-next-line import/no-cycle
import { filterTags, formatDateToMonthShort, avatarText } from '@core/utils/filter'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TicketLeftSidebar from './TicketLeftSidebar.vue'
import useEmail from './useEmail'
import TicketCompose from './TicketCompose.vue'
import TicketMediaCard from './TicketMediaCard.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BAvatar,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,
    vSelect,

    // App SFC
    TicketLeftSidebar,
    TicketCompose,
    TicketMediaCard,
  },
  setup() {
    const toast = useToast()

    const perPage = ref(10)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('updated_at')
    const isSortDirDesc = ref(false)

    const { resolveLabelColor, resolvePrioColor } = useEmail()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Emails & EmailsMeta
    const emails = ref([])
    const emailsMeta = ref({})

    const emailPriority = [
      { title: 'Urgent', color: 'danger', route: { name: 'tickets-priority', params: { priority: 1 } } },
      { title: 'High', color: 'warning', route: { name: 'tickets-priority', params: { priority: 2 } } },
      { title: 'Medium', color: 'info', route: { name: 'tickets-priority', params: { priority: 3 } } },
      { title: 'Low', color: 'secondary', route: { name: 'tickets-priority', params: { priority: 4 } } },
    ]

    // ------------------------------------------------
    // Mail Selection
    // ------------------------------------------------
    const selectedEmails = ref([])
    const toggleSelectedMail = mailId => {
      const mailIndex = selectedEmails.value.indexOf(mailId)

      if (mailIndex === -1) selectedEmails.value.push(mailId)
      else selectedEmails.value.splice(mailIndex, 1)
    }
    const selectAllEmailCheckbox = computed(() => emails.value.length && (emails.value.length === selectedEmails.value.length))
    const isSelectAllEmailCheckboxIndeterminate = computed(() => Boolean(selectedEmails.value.length) && emails.value.length !== selectedEmails.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedEmails.value = val ? emails.value.map(mail => mail.ticket_id) : []
    }
    // ? Watcher to reset selectedEmails is somewhere below due to watch dependecy fullfilment

    // Compose
    const shallShowEmailComposeModal = ref(false)

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const userData = getUserData()

    return {
      toast,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      userData,

      // UI
      perfectScrollbarSettings,

      // Emails & EmailsMeta
      emails,
      emailsMeta,
      emailPriority,

      // Mail Selection
      selectAllEmailCheckbox,
      isSelectAllEmailCheckboxIndeterminate,
      selectedEmails,
      toggleSelectedMail,
      selectAllCheckboxUpdate,

      // UI Filters
      avatarText,
      filterTags,
      formatDateToMonthShort,

      // useEmail
      resolveLabelColor,
      resolvePrioColor,

      // Compose
      shallShowEmailComposeModal,

      // Left Sidebar Responsiveness
      mqShallShowLeftSidebar,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      order: 'asc',
      filterChannel: null,
      dataMeta: '',
      reference: {},
      roleFilter: '',
      prevRoleFilter: '',
      updated: [],
      holidays: [],
    }
  },
  computed: {
    orderToggleIcon() {
      return this.order === 'asc' ? 'ChevronsUpIcon' : 'ChevronsDownIcon'
    },
  },
  created() {
    if (this.userData.group_id > 100) {
      this.$router.push({ name: 'tickets-escalate-folder', params: { folder: 'all' } })
    }
    if (this.$route.params.label) {
      this.roleFilter = {
        status_id: Number(this.$route.params.label),
      }
    } else if (this.$route.params.priority) {
      this.roleFilter = {
        priority_id: Number(this.$route.params.priority),
      }
    } else if (this.$route.params.channel) {
      this.roleFilter = {
        channel_id: Number(this.$route.params.channel),
      }
    }
    this.getTickets()
    this.$watch(
      () => this.$route.params,
      // eslint-disable-next-line no-unused-vars
      (toParams, _previousParams) => {
        if (toParams.label) {
          this.currentPage = 1
          this.roleFilter = {
            status_id: Number(toParams.label),
          }
        } else if (toParams.priority) {
          this.currentPage = 1
          this.roleFilter = {
            priority_id: Number(toParams.priority),
          }
        } else if (toParams.channel) {
          this.currentPage = 1
          this.roleFilter = {
            channel_id: Number(toParams.channel),
          }
        } else {
          this.roleFilter = ''
        }
        this.emails = []
        this.getTickets()
      },
    )
  },
  methods: {
    updateFilterChannel() {
      this.currentPage = 1
      this.emails = []
      this.getTickets()
    },
    toggleOrder() {
      this.order = (this.order === 'asc') ? 'desc' : 'asc'
      this.emails = []
      this.getTickets()
    },
    clearSearchData() {
      this.currentPage = 1
      this.searchQuery = ''
      this.getTickets()
    },
    newTicket() {
      this.currentPage = 1
      this.emails = []
      this.getTickets()
    },
    updateSelectedEmailsLabel(label) {
      if (this.selectedEmails.length !== 0) {
        this.updated = []
        this.selectedEmails.map(val => {
          const params = {
            ticket_id: val,
            priority_id: label,
          }
          this.$http.post('/ticket/priority', params, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
          })
            .then(resp => {
              if (resp.status === 200) {
                this.updated.push(val)
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Updating Ticket Priority Success',
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                  },
                })
                this.checkBox()
              }
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Error Updating Ticket Priority',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
          return true
        })
      }
    },
    checkBox() {
      if (this.selectedEmails.length === this.updated.length) {
        this.selectedEmails = []
        this.updated = []
        this.getTickets()
      }
    },
    moveSelectedEmailsToFolder(target, type) {
      const params = {
        ticket_id: target.ticket_id,
      }
      const targetUrl = '/ticket/accept'
      if (type === 'spam') {
        params.status_id = 6
      } else {
        params.status_id = 1
      }
      this.$http.post(targetUrl, params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            this.$swal({
              icon: 'success',
              title: 'Update Completed!',
              text: 'Ticket status has been updated.',
              showConfirmButton: false,
            })
            setTimeout(() => {
              this.getTickets()
              this.$swal.close()
            }, 1000)
          }
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error Updating Ticket status',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    viewDetail(ticket) {
      this.$router.push({ name: 'tickets-view', params: { id: ticket.ticket_number } })
    },
    getTickets() {
      document.getElementById('loading-bg').style.display = 'block'

      const params = {
        length: this.perPage,
        order: this.order,
        filters: [],
      }
      if (this.filterChannel !== null) {
        params.channel = this.filterChannel.channel_id
      }
      if (this.searchQuery !== '') {
        params.keywords = this.searchQuery
      }
      let target = '/inbox'
      params.filters.push({
        column: 'helpdesk_agent_id',
        value: this.userData.id,
      })
      if (this.roleFilter) {
        Object.entries(this.roleFilter).forEach(entry => {
          const [key, valuez] = entry
          params.filters.push({
            column: key,
            value: valuez,
          })
        })
      }
      if (this.currentPage !== 1) {
        target += `?page=${this.currentPage}`
      } else {
        this.emails = []
      }

      if (Object.entries(this.reference).length === 0) {
        this.$http.post('ref/tx', true, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          },
        })
          .then(resp => {
            if (resp.status === 200) {
              this.reference = resp.data

              const channels = resp.data.channel
              const channel = []

              channels.map(data => {
                if (data.name !== 'Email' && data.name !== 'Whatsapp' && data.name !== 'Contact Form') {
                  channel.push(data)
                }
                return data
              })
              this.reference.channeledit = channel

              const liburAll = this.reference.holidays
              const libur = []

              Object.keys(liburAll).map(data => {
                if (data !== 'created-at') {
                  const tgl = moment(data).format('YYYY-MM-DD')
                  libur.push(tgl)
                }
                return data
              })
              this.holidays = libur
            }
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching Refences',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
      this.$http.post(target, params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            resp.data.data.map(tix => {
              this.emails.push(tix)
              return true
            })

            this.dataMeta = resp.data.meta
            document.getElementById('loading-bg').style.display = 'none'
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Ticket list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    scrollHandle() {
      if (this.emails.length !== 0 && this.emails.length === (this.currentPage * this.perPage)) {
        this.currentPage += 1
        this.getTickets()
      }
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
